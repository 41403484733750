import Layout from "./common/layout"
import Head from "./common/Head"
import MtProgram from "pages/company/MtProgram"

const MTProgram = () => {
  return (
    <>
      <Head
        title={"Management Trainee Program 2024 - OnPoint Trailblazers"}
        description={
          "The Management Trainee program at OnPoint is a dynamic and comprehensive training experience focused on developing your leadership and management skills."
        }
        showCaptcha={false}
        image={
          "https://files.onpoint.vn/jpg/2023/10/31/S3DYC1LX6S_5036c0ce-ed7c-457a-931b-c414548b3582"
        }
      />
      <Layout displayApplyBtn={false}>
        <MtProgram />
      </Layout>
    </>
  )
}

export default MTProgram
